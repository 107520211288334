import { FC } from 'react';
import Icon, { IconType } from '../icon';
import { Stack } from '@mui/material';
import { createUseStyles } from 'react-jss';
import Text from '../text';

interface Props {
  label: string;
  icon?: IconType;
  color?: 'black' | 'grey' | 'link' | 'error';
  onClick: () => void;
  size?: 'small' | 'medium';
  disabled?: boolean;
}

const useStyles = createUseStyles({
  flatButton: {
    cursor: 'pointer',
  },
});

const FlatButton: FC<Props> = ({
  label,
  icon,
  onClick,
  color,
  size = 'medium',
  disabled = false,
}) => {
  const styles = useStyles();
  const compColor = disabled ? 'grey' : color;
  const IconEl = icon ? <Icon name={icon} size={size} color={compColor} /> : null;

  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  };

  return (
    <Stack
      className={styles.flatButton}
      flexDirection="row"
      alignItems="center"
      role="button"
      onClick={handleClick}
      gap={0.4}
    >
      {IconEl}
      <Text color={compColor} size={size}>
        {label}
      </Text>
    </Stack>
  );
};

export default FlatButton;
