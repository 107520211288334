import { FC, useState } from 'react';
import { useQuery } from 'react-query';

import Chip from '@/components/chip';
import NoneChip from '@/components/chip/none-chip';
import PolicyCard from '@/components/policy-card';
import ReadonlyView from '@/components/form/readonly-view';
import { ReadonlyField } from '@/components/form/readonly-view/helpers';
import { PolicyMeta } from '@/lib/models/policy.model';
import { getOrgPolicyWithContext } from '@/lib/services/policy.service';
import {
  createUser,
  deleteUser,
  getUserGroupsPoliciesContextsById,
  patchUser,
} from '@/lib/services/user.service';
import { Stack } from '@mui/material';
import { ContextProviderMeta } from '@/lib/models/context-provider';
import Drawer, { DrawerProps } from '../';
import DrawerContent from '../content';
import DrawerHeader from '../header';
import { UserModel } from '@/lib/models/user.model';
import { GroupMeta } from '@/lib/models/group.model';
import useToast from '@/hooks/use-toast.hook';
import useModal from '@/hooks/use-modal.hook';
import { ViewState } from '@/lib/helpers';
import useQueryHelper from '@/hooks/use-query-helper';
import useFormHandle from '@/hooks/use-form-handle.hook';
import InputControl from '@/components/form/input-control';
import DrawerButtons from '@/components/form/drawer-buttons';
import { getNonSyncGroupOptions } from '@/lib/services/group.service';
import DrawerToolbar from '../toolbar';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';

interface Props extends DrawerProps {
  id: string;
}

const fields: ReadonlyField[] = [
  { label: 'Name', prop: 'name' },
  { label: 'Email', prop: 'email' },
  { label: 'SurePath Groups', prop: 'adminGroups' },
  { label: 'Sync Groups', prop: 'syncGroups' },
  { label: 'Source', prop: 'source' },
  { label: 'Policies', prop: 'groups-section', propType: 'section' },
];

const formModel = new UserModel();

const getReadonlyData = (data: UserModel | null, groups: GroupMeta[]) => {
  if (!data) {
    return {};
  }

  const { name, email, sourceLabel } = data;
  const syncGroups = groups.filter(({ isExternal }) => isExternal);
  const adminGroups = groups.filter(({ isExternal }) => !isExternal);

  return {
    name: name || <NoneChip notAvailable />,
    email: email,
    syncGroups: syncGroups.length ? (
      <Stack direction="row" gap={1} flexWrap="wrap">
        {syncGroups.map(({ id, name }) => (
          <Chip key={id} label={name} />
        ))}
      </Stack>
    ) : (
      <NoneChip />
    ),
    adminGroups: adminGroups.length ? (
      <Stack direction="row" gap={1} flexWrap="wrap">
        {adminGroups.map(({ id, name }) => (
          <Chip key={id} label={name} />
        ))}
      </Stack>
    ) : (
      <NoneChip />
    ),
    source: sourceLabel,
  };
};

type UserQueryResult = [UserModel | null, PolicyMeta[], GroupMeta[], ContextProviderMeta[]];

export const UserDrawer: FC<Props> = ({ id, open, onClose, className, onChange }) => {
  const { toast, errorToast } = useToast();
  const { openModal } = useModal();
  const [userId, setUserId] = useState(id || null);
  const isNew = !userId;
  const [mode, setMode] = useState<ViewState>(isNew ? 'add' : 'view');
  const { canChangeUsers } = useFeature();

  const query = useQuery<UserQueryResult>(
    [QueryKey.UserGroupsPoliciesView, userId],
    async () => {
      const [userData, orgPolicyData] = await Promise.all([
        getUserGroupsPoliciesContextsById(userId!),
        getOrgPolicyWithContext(),
      ]);

      const [user, groupMeta, policyMeta, contextMeta] = userData;
      const [orgPolicy, orgContextMeta] = orgPolicyData;

      if (!user) {
        return [null, [], [], []];
      }

      const policies = [orgPolicy!.meta];

      Array.from(policyMeta.entries()).forEach(([, policy]) => {
        policies.push(policy);
      });

      const groups = Array.from(groupMeta.entries()).map(([, group]) => group);
      const contexts: ContextProviderMeta[] = [];

      Array.from(contextMeta.entries()).forEach(([, context]) => contexts.push(context));
      Array.from(orgContextMeta.entries()).forEach(([, context]) => contexts.push(context));

      return [user, policies, groups, contexts];
    },
    {
      enabled: !isNew,
    }
  );

  const { data: nonSyncGroupOpts } = useQuery([QueryKey.GroupsNonSyncOptions], async () =>
    getNonSyncGroupOptions()
  );

  const { showChildren } = useQueryHelper(query);

  const { data, refetch } = query;
  const [userData, policies = [], groups = [], contexts = []] = data || [];
  const user = userData || new UserModel();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schema,
    validateOnChange: !isNew,
    onSubmit: async (updatedUser) => {
      if (!userId) {
        const { id: newUserId, inserted, error } = await createUser(updatedUser);

        if (!inserted) {
          setFormMessage({ level: 'error', content: error || 'The user was not created' });
          return;
        }

        toast('The user was created');
        setUserId(newUserId!);
        onChange && onChange();
        setMode('view');
        return;
      }

      // ensure that sync groups are not removed from the user
      const syncGroups = user.groups.filter(
        (groupId) => !nonSyncGroupOpts?.some(({ value }) => value === groupId)
      );
      updatedUser.groups = [...syncGroups, ...updatedUser.groups];

      const { updated, error } = await patchUser(
        user.id!,
        updatedUser.propSlice(['name', 'email', 'groups'])
      );

      if (!updated) {
        setFormMessage({ level: 'error', content: error || 'The user was not updated' });
        return;
      }

      toast('The user was updated');
      refetch();
      onChange && onChange();
      setMode('view');
    },
  });

  const { canSubmit, isSubmitting, loadData, setFormMessage, handleSubmit } = formHandle;

  const editMode = ['edit', 'add'].includes(mode);

  const handleCancel = () => {
    if (isNew) {
      onClose();
      return;
    }

    setMode('view');
    loadData(user);
  };

  const handleEdit = () => {
    setMode('edit');
  };

  const handleDelete = () => {
    openModal('confirm', {
      title: 'Confirm Delete',
      content: `Are you sure you want to delete ${user.name}?`,
      onClose: (confirm: boolean) => {
        if (confirm) {
          deleteUser(userId!).then(({ deleted, error }) => {
            if (deleted) {
              setUserId('');
              onClose();
              toast('The user was deleted');
              onChange && onChange();
              return;
            }

            errorToast(error || 'The user was not deleted');
          });
        }
      },
    });
  };

  const isExternal = user.isExternal;
  const canDelete = user.canDelete;
  const hasLogin = user.hasLogin;
  const canEditProfile = !isExternal && !hasLogin;

  const deleteTooltip = isExternal
    ? 'You cannot delete a user that comes from sync'
    : 'You cannot delete a user that has signed in';

  return (
    <Drawer open={open} onClose={onClose} className={className} query={query}>
      <DrawerHeader onClose={onClose}>{isNew ? 'Add User' : user.name}</DrawerHeader>
      <DrawerContent>
        {!isNew && canChangeUsers && (
          <DrawerToolbar
            onEdit={handleEdit}
            onDelete={handleDelete}
            canDelete={canDelete}
            deleteTooltip={!canDelete ? deleteTooltip : ''}
          />
        )}
        {!editMode && showChildren && (
          <>
            <ReadonlyView fields={fields} data={getReadonlyData(user, groups)} />

            <Stack gap={2}>
              <Stack gap={2}>
                {policies.map((policy) => {
                  const policyGroups = groups.filter(({ policies }) =>
                    policies.includes(policy.id)
                  );
                  const policyContexts = [];
                  for (const contextId of policy.contextDataSources.enabled) {
                    const context = contexts.find(({ id }) => id === contextId);
                    if (context) policyContexts.push(context);
                  }
                  return (
                    <PolicyCard
                      key={policy.id}
                      policy={policy}
                      groups={policyGroups}
                      contextProviders={policyContexts}
                    />
                  );
                })}
              </Stack>
            </Stack>
          </>
        )}

        {editMode && showChildren && (
          <Form formHandle={formHandle} query={query}>
            <InputControl
              name="name"
              label="Name"
              formHandle={formHandle}
              autoFocus
              disabled={!canEditProfile}
            />

            <InputControl
              name="email"
              label="Email"
              formHandle={formHandle}
              disabled={!canEditProfile}
            />

            <InputControl
              type="select-multi"
              name="groups"
              label="SurePath Groups"
              formHandle={formHandle}
              options={nonSyncGroupOpts}
            />

            <DrawerButtons
              submit
              cancel
              isNew={isNew}
              canSubmit={canSubmit}
              canCancel={!isSubmitting}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              formHandle={formHandle}
            />
          </Form>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default UserDrawer;
