import { JsonObject } from '../helpers';
import { OrgBasicInfo, OrgModel } from '../models/org.model';
import { findOne, getOrgFilter, patchOne } from './atlas-data-api.service';

const COLLECTION = 'orgs';

export const getOrg = async (): Promise<OrgModel | null> => {
  const { orgId } = getOrgFilter();

  const params = {
    filter: {
      _id: orgId,
    },
  };

  const response = await findOne(COLLECTION, params);

  return response ? new OrgModel(response) : null;
};

export const patchOrg = async (data: JsonObject): Promise<boolean> => {
  const { orgId } = getOrgFilter();

  const filter = {
    _id: orgId,
  };

  // convert portalDefaultModelId to an oid
  if (data.portalDefaultModelId) {
    data.portalDefaultModelId = { $oid: data.portalDefaultModelId };
  }

  // help to ensure these fields are readonly
  delete data.name;
  delete data.domains;

  const response = await patchOne(COLLECTION, filter, data);

  return response === null;
};

export const getOrgBasicInfoAppData = async (): Promise<OrgBasicInfo | null> => {
  const { orgId } = getOrgFilter();

  const params = {
    filter: {
      _id: orgId,
    },
    projection: {
      name: 1,
      mode: 1,
      trial: 1,
      isTrial: 1,
    },
  };

  const response = await findOne(COLLECTION, params);

  return response ? new OrgModel(response).basicInfo : null;
};
