import { FC } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { getFirstLastName } from '../../lib/helpers';
import { createUseStyles } from 'react-jss';
import Menu from '../menu';
import UserBadge from './user-badge';
import Text from '../text';
import Button from '../button';
import useApp from '@/hooks/use-app.hook';

const useStyles = createUseStyles((theme: Theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: 16,
    paddingTop: 16,
  },
}));

const UserMenu: FC = () => {
  const styles = useStyles();
  const { user, signOut } = useApp();
  const { displayName, email, assumed } = user!;
  const username = displayName || '';
  const { first, last } = getFirstLastName(username);
  const hasName = Boolean(first) || Boolean(last);
  const assumeActive = !!assumed;

  let NameEl = <Text size="large">{email}</Text>;

  if (hasName) {
    NameEl = (
      <Stack gap={0.5}>
        <Text size="large">{[first, last].join(' ')}</Text>
        <Text color="grey">{email}</Text>
      </Stack>
    );
  }

  const handleSignOut = () => {
    signOut();
  };

  return (
    <Box>
      <Menu trigger={<UserBadge user={user!} />}>
        {NameEl}{' '}
        {!assumeActive && (
          <Box className={styles.footer} display="flex" justifyContent="flex-end">
            <Button label="Sign Out" onClick={handleSignOut} size="small" />
          </Box>
        )}
      </Menu>
    </Box>
  );
};

export default UserMenu;
