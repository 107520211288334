import { FC, useCallback, useRef, useState } from 'react';
import Page, { PageProps } from '@/components/page';
import PageHeader, { PageBlurb } from '@/components/page/header';
import { getConnectors, maskConnectorStatus } from '@/lib/services/connector.service';
import StatusChip from '@/components/chip/status-chip';
import { Box, Stack } from '@mui/material';
import Text from '@/components/text';
import ConnectorIcon from '@/components/connector-icon';
import DataTable, { DataTableInstance } from '@/components/data-table';
import { DtColumn, DtFilter, DtSort } from '@/components/data-table/helpers';
import useDrawer from '@/hooks/use-drawer';
import OpenDrawerButton from '@/components/drawers/open-drawer-button';
import Button from '@/components/button';
import Tooltip from '@/components/tooltip';
import { getTypedConnector } from '@/lib/models/connector';
import { QueryKey } from '@/lib/query-client';
import DomSize from '@/components/dom-size';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const columns: DtColumn[] = [
  { name: 'name', label: 'Name', flex: 3, sortable: true },
  { name: 'type', label: 'Type', flex: 1, sortable: true },
  { name: 'status', label: 'Status', sortable: true },
  { name: 'action', label: '', sortable: false },
];

const ConnectorsPage: FC<PageProps> = () => {
  const { openDrawer, DrawerEl } = useDrawer('connector');
  const [selectedRowId, setSelectedRowId] = useState('');
  const dtRef = useRef<DataTableInstance>(null);

  const { canChangeConnector, getTooltip, loading: featureLoading, ragStatus } = useFeature();
  const canChange = canChangeConnector();

  const handleRefresh = () => {
    dtRef.current?.refresh();
  };

  const handleOpenDrawer = useCallback(
    (connectorId?: string) => {
      setSelectedRowId(connectorId || '');
      openDrawer({
        id: connectorId || null,
        onChange: handleRefresh,
        onClose: () => {
          setSelectedRowId('');
        },
      });
    },
    [openDrawer]
  );

  const loadData = useCallback(
    async (page: number, pageSize: number, sort: DtSort, filter: DtFilter) => {
      const dataset = await getConnectors(page, pageSize, sort, filter);
      const maskedConnectors = maskConnectorStatus(dataset.rows, ragStatus);

      return {
        ...dataset,
        rows: maskedConnectors.map((model) => {
          const { id, name, type, typeLabel, status } = getTypedConnector(model);
          return {
            id,
            name: (
              <Stack direction="row" gap={1.5} alignItems="center" maxWidth="100%">
                <ConnectorIcon connectorType={type} />
                <Box>{name}</Box>
              </Stack>
            ),
            type: <Text size="small">{typeLabel}</Text>,
            status: <StatusChip value={status} size="small" />,
            action: (
              <Box width="100%" display="flex" justifyContent="flex-end">
                <OpenDrawerButton onClick={() => handleOpenDrawer(id!)} />
              </Box>
            ),
          };
        }),
      };
    },
    [handleOpenDrawer, ragStatus]
  );

  if (featureLoading) {
    return null;
  }

  return (
    <Page title="Connectors">
      <PageHeader>
        <PageBlurb>Manage connections to third party services and data sources.</PageBlurb>

        <>
          <Tooltip title={getTooltip('add-connector')} disabled={canChange}>
            <Button
              label="Add Connector"
              icon="plus"
              size="small"
              onClick={() => handleOpenDrawer()}
              disabled={!canChange}
            />
          </Tooltip>
        </>
      </PageHeader>
      <PageFeatureToast featureId="change-connector" can={canChange} />
      <DomSize>
        <DataTable
          queryKey={QueryKey.ConnectorsDataset}
          columns={columns}
          onLoad={loadData}
          ref={dtRef}
          search={true}
          searchPlaceholder="Search by name"
          sort={{ columnName: 'name', direction: 'asc' }}
          selectedRowId={selectedRowId}
          poll={15000}
        />
      </DomSize>
      {DrawerEl}
    </Page>
  );
};

export default ConnectorsPage;
