import { AxiosError, AxiosResponse } from 'axios';
import { JsonObject } from '../helpers';
import { BaseModel } from '../models/base.model';

const DEFAULT_SERVICE_ERROR = 'There was a service error';

export const SAFE_LIMIT = 1000;

export type FieldError = {
  type: string;
  value: unknown;
  msg: string;
  path: string;
  location: string;
};

export type CreateResponse<T = BaseModel> = {
  created: boolean;
  error?: string;
  fieldErrors?: FieldError[];
  data?: JsonObject;
  model?: T;
};

export type PatchResponse = { patched: boolean; error?: string; fieldErrors?: FieldError[] };

export type InsertResponse = { inserted: boolean; error?: string; id?: string };

export type UpdateResponse = { updated: boolean; error?: string };

export type DeleteResponse = { deleted: boolean; error?: string };

export type ApiResponse = JsonObject | JsonObject[] | null;

export const handleResponse = (response: AxiosResponse | null): ApiResponse => {
  if (!response?.data) {
    return null;
  }

  if (Array.isArray(response?.data)) {
    return response?.data as JsonObject[];
  }

  return response?.data as JsonObject;
};

export const handleCreateResponse = (response: AxiosResponse | null): CreateResponse => {
  if (!response?.data) {
    return { created: false, error: DEFAULT_SERVICE_ERROR };
  }

  return { created: true, data: response.data as JsonObject };
};

export const handleCreateErrorResponse = (error: AxiosError): CreateResponse => {
  if (!error.response) {
    return { created: false, error: DEFAULT_SERVICE_ERROR };
  }

  const { status, data } = error.response;

  if (status === 400 && Array.isArray(data)) {
    return { created: false, fieldErrors: data as FieldError[] };
  }

  return { created: false, error: DEFAULT_SERVICE_ERROR };
};

export const handlePatchResponse = (response: AxiosResponse | null): PatchResponse => {
  if (!response?.data) {
    return { patched: false, error: DEFAULT_SERVICE_ERROR };
  }

  return { patched: true };
};

export const handlePatchErrorResponse = (): PatchResponse => {
  return { patched: false, error: DEFAULT_SERVICE_ERROR };
};

export const handleDeleteResponse = (): DeleteResponse => {
  return { deleted: true };
};

export const handleDeleteErrorResponse = (): DeleteResponse => {
  return { deleted: false, error: DEFAULT_SERVICE_ERROR };
};
